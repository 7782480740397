@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(/static/media/Roboto-Regular.31012f9b.woff) format('woff');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url(/static/media/Roboto-Bold.3f2cbde6.woff) format('woff');
}

@font-face {
  font-family: 'Roboto-Italic';
  src: local('Roboto-Italic'), url(/static/media/Roboto-Italic.0d0bf30e.woff) format('woff');
}


@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(/static/media/Roboto-Medium.e2d3fd03.woff) format('woff');
}



body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
  font-size: 14px;
  margin: 0px;
}

main {
  background-color: #F7F8FA;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

a,
a:hover,
a:active {
  text-decoration: none;
  color: #4A4A4A;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

ol,
ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

input[type=text][disabled], input:disabled {
  cursor: not-allowed;
}

.text-danger {
  color: red;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

input[type=number] {
  -moz-appearance: textfield,
}
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ck-editor__editable_inline {
    min-height: 300px;
}
.ant-picker-dropdown {
    z-index: 9999 !important;
}
body > iframe {
  display: none;
}

